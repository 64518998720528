import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import Numbers from './phone/numbers';
// import * as styles from './phone.module.scss';

const Phone = ({
	data: {
		markdownRemark: {
			fields: { slug },
			html,
		},
	},
}) => (
	<Page slug={slug} body={html}>
		<Numbers />
	</Page>
);

export const phoneQuery = graphql`
	query phoneQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			fields {
				slug
			}
			html
		}
	}
`;

export default Phone;
