import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

const all = [];
const uuidMap = {};
const groupMap = {};
const localeMap = {};
const viewIdMap = {};
const letterMap = {};
const categoryMap = {};
const categoryGroupMap = {};

export const GROUP_BRAND = 'brand';
export const GROUP_CULINARY = 'culinary';

export const useLocationByUuid = (uuid) => {
	invokeQueryAndMapData();

	if (uuid in uuidMap) {
		return uuidMap[uuid];
	}


	return null;
};

export const useLocationByViewId = (viewId) => {
	invokeQueryAndMapData();

	if (viewId in viewIdMap) {
		return viewIdMap[viewId];
	}

	return null;
};

export const useLocationsByLetter = (letter) => {
	invokeQueryAndMapData();

	if (letter in letterMap) {
		return filterLocationsByLocale(
			letterMap[letter]
		);
	}

	return [];
};

export const useLocationsByGroup = (group) => {
	invokeQueryAndMapData();

	if (group in groupMap) {
		return filterLocationsByLocale(
			groupMap[group]
		);
	}

	return [];
};

export const useLocationsByCategory = (category) => {
	invokeQueryAndMapData();

	if (category in categoryMap) {
		return filterLocationsByLocale(
			categoryMap[category]
		);
	}

	return [];
};

export const useLocationsByGroupAndCategory = (group, category) => {
	invokeQueryAndMapData();

	if (group in categoryGroupMap) {
		const groupMap = categoryGroupMap[group];
		if (category in groupMap) {
			return filterLocationsByLocale(
				groupMap[category]
			);
		}
	}

	return [];
};

const filterLocationsByLocale = (locations) => {
	const {
		currentLocale
	} = useContext(LocaleContext);

	return locations.filter(
		({ locale }) => locale === currentLocale
	);
};

const invokeQueryAndMapData = () => {
	const {
		allMarkdownRemark: {
			edges: locations
		}
	} = useStaticQuery(query);

	const {
		currentLocale
	} = useContext(LocaleContext);

	const mapItem = ({
		node: {
			frontmatter: data,
			fields
		}
	}) => ({
		...data,
		...fields
	});

	if (all.length === 0) {
		for (let location of locations) {
			const item = mapItem(location);
			const {
				uuid,
				name,
				group,
				viewId,
				locale,
				categories
			} = item;

			if (Array.isArray(categories)) {
				for (let category of categories) {
					categoryGroupMap[group] = categoryGroupMap[group] || {};
					categoryGroupMap[group][category] = categoryGroupMap[group][category] || [];
					categoryGroupMap[group][category].push(item);

					categoryMap[category] = categoryMap[category] || [];
					categoryMap[category].push(item);
				}
			}

			const letter = name.charAt(0).toLowerCase();
			letterMap[letter] = letterMap[letter] || [];
			localeMap[locale] = localeMap[locale] || [];
			groupMap[group] = groupMap[group] || [];
			letterMap[letter].push(item);
			localeMap[locale].push(item);
			groupMap[group].push(item);
			viewIdMap[viewId] = item;
			uuidMap[uuid] = item;

			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
};

export default invokeQueryAndMapData;

const query = graphql`
	query useLocations {
		allMarkdownRemark(
	    	filter: {
		        frontmatter: {
		          type: {
		            eq: "location"
		          }
		        }
		  	}
		  	sort: {
		  		fields: frontmatter___name
		  	}
	  	)
	  	{
			edges {
				node {
					frontmatter {
						name
						group
						categories
						logo {
							publicURL
						}
						hours {
							date
							time
						}
						address
						contact {
							phone
							email
							url
						}
						viewId
					}
					fields {
						slug
						locale
					}
				}
			}
		}
	}
`;