import PropTypes from 'prop-types';

import { format, toUri } from '../../utils/phone';
import { useLocationsByLetter } from '../../hooks/use-locations';

import * as styles from './numbers.module.scss';

//
// LETTER
//
export const Letter = ({ code, items }) => (
	<div className={styles.letter}>
		<h2 className={styles.header}>{code}</h2>

		<div>
			{items.map(({ name, contact: { phone } }, key) => {
				const href = toUri(phone);
				const text = format(phone);
				const Tag = (href && 'a') || 'div';

				return (
					<Tag key={key} href={href} className={styles.item}>
						<div className={styles.name}>{name}</div>
						<div className={styles.number}>{text}</div>
					</Tag>
				);
			})}
		</div>
	</div>
);

Letter.defaultProps = {
	items: [],
	code: null,
};

Letter.propTypes = {
	code: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			contact: PropTypes.shape({
				phone: PropTypes.string.isRequired,
			}).isRequired,
		})
	).isRequired,
};

//
// WRAPPER
//
const Wrapper = () => {
	const items = [];
	const charA = 'a'.charCodeAt(0);
	const charZ = 'z'.charCodeAt(0);

	for (let i = charA; i <= charZ; i++) {
		const key = String.fromCharCode(i);
		const locations = useLocationsByLetter(key);
		if (locations.length === 0) {
			continue;
		}

		items.push(<Letter key={key} code={key} items={locations} />);
	}

	return items;
};

export default Wrapper;
